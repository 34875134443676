import NavBar from "../../components/NavBar";
import { useContext, useEffect, useState, useRef } from "react";
import { ThemeContext, LangContext } from "../../utils/context";
import frontImg from "../../assets/moutains-front.svg";
import gsap from "gsap";
import Footer from "../../components/Footer";
import "./contact.css";

const Contact = () => {
  const { theme } = useContext(ThemeContext);
  const { lang } = useContext(LangContext);
  // Références pour les éléments des deux boutons
  const linkRef = useRef(null); // Premier bouton
  const pinkRef = useRef(null); // Couleur du premier bouton

  const [to, setTo] = useState("");
  const [subject, setSubject] = useState("");
  const [text, setText] = useState("");
  const [status, setStatus] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    // send msg in database
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    // Création de la timeline GSAP pour les animations des deux boutons
    const hoverTL1 = gsap.timeline({ paused: true });

    // Animation pour le premier bouton
    hoverTL1.to(pinkRef.current, {
      width: "calc(100% + 1.3em)",
      ease: "Elastic.easeOut(0.25)",
      duration: 0.4,
    });
    hoverTL1.to(pinkRef.current, {
      width: "2em",
      left: "calc(100% - 1.45em)",
      ease: "Elastic.easeOut(0.4)",
      duration: 0.6,
    });

    // Gestion des événements de survol sur les deux boutons
    const handleMouseEnter1 = () => hoverTL1.play();
    const handleMouseLeave1 = () => hoverTL1.reverse();

    const linkElement1 = linkRef.current;
    linkElement1.addEventListener("mouseenter", handleMouseEnter1);
    linkElement1.addEventListener("mouseleave", handleMouseLeave1);

    // Nettoyage des événements lors du démontage du composant
    return () => {
      linkElement1.removeEventListener("mouseenter", handleMouseEnter1);
      linkElement1.removeEventListener("mouseleave", handleMouseLeave1);
    };
  }, []);
  return (
    <>
      <div className={`bgConfig ${theme === "dark" ? "dark" : "day"}`}></div>
      {theme === "dark" ? <div className="starsConfig"></div> : ""}
      <header>
        <NavBar active="contact"></NavBar>
      </header>
      <section className="wrapper contactSec">
        <section className="content">
          <form onSubmit={handleSubmit}>
            <h1>{lang === "en" ? "Contact-me" : "Contactez-moi"}</h1>
            <input
              type="email"
              id="email"
              name="email"
              value={to}
              onChange={(e) => setTo(e.target.value)}
              required
              placeholder="Your email : firstname@example.fr"></input>
            <input
              type="text"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              required
              placeholder={
                lang === "en" ? "Msg's object" : "Objet du message"
              }></input>
            <textarea
              id="message"
              name="message"
              value={text}
              onChange={(e) => setText(e.target.value)}
              required
              placeholder={
                lang === "en"
                  ? "Type your msg here..."
                  : "Entrez votre msg ici..."
              }></textarea>
            <button ref={linkRef} className="link" type="submit">
              <div ref={pinkRef} className="color"></div>
              <span className="whitetxt">
                {lang === "en" ? "SEND MY MSG" : "ENVOYER MON MSG"}
              </span>
              <i className="fa-solid fa-arrow-right"></i>
            </button>
          </form>
          {console.log(status)}
        </section>
      </section>
      <object
        className="frontImg"
        type="image/svg+xml"
        data={frontImg}
        title="front mountains"></object>
      <Footer />
    </>
  );
};

export default Contact;
