import "./lastDisplayer.css";
import lppIMG from "../../assets/lesPtitsPlats.webp";
import sportseeIMG from "../../assets/sportsee.webp";
import portfolioIMG from "../../assets/portfolio.webp";
import { LangContext } from "../../utils/context";
import { useContext } from "react";

const LastDisplayer = () => {
  const { lang } = useContext(LangContext);
  return (
    <section className="lastDisplayerCont">
      <h1>
        {lang === "en"
          ? "Let's talk about ⸱⸱⸱ my lastest projects"
          : "Parlons de mes derniers projets"}
      </h1>
      <div className="lastDisplayer">
        <div>
          <h2>Portfolio</h2>
          <img src={portfolioIMG} alt="project img" />
        </div>
        <div>
          <h2>LesPetitsPlats</h2>
          <img src={lppIMG} alt="project img" />
        </div>
        <div>
          <h2>HrNet</h2>
          <img src={sportseeIMG} alt="project img" />
        </div>
      </div>
    </section>
  );
};

export default LastDisplayer;
