const mooc = {
  en: {
    dev: [
      "Discover how algorithms work",
      "Manage code with Git and GitHub",
      "Create responsive websites with Bootstrap 4",
      "Create responsive websites with Bootstrap 5",
      "Simplify your CSS with Sass",
      "Build your website with PHP & MySql",
      "Adopt an MVC architecture in PHP",
      "Debug your website's interface",
      "Get started with React",
      "Create a complete React application",
      "Manage your React application with Redux and Redux Toolkit",
      "Learn to program in JavaScript",
      "Move to Full Stack with Node.js, Express, and MongoDB",
      "Discover TypeScript",
    ],
    design: [
      "Embrace UX practices in action",
      "UX design: discover the fundamentals",
      "Create and apply a style guide",
    ],
    project: [
      "Get introduced to agile project management",
      "Become an agile developer",
      "Adopt an agile culture",
    ],
    cyber: [
      "Discover the basics of digital security",
      "Explore the world of cybersecurity",
      "Secure your networks with VPNs and Firewalls",
    ],
    mark: ["Set up an information monitoring system"],
    data: ["Use ChatGPT to improve your productivity"],
    management: [
      "Uncover your leadership style",
      "Manage a team on a daily basis",
    ],
    sysres: ["Discovery of the information systems's world"],
  },
  fr: {
    dev: [
      "Découvrez le fonctionnement des algorithmes",
      "Gérez du code avec Git et GitHub",
      "Créez des sites web responsive avec Bootstrap 4",
      "Créez des sites web responsives avec Bootstrap 5",
      "Simplifiez-vous le CSS avec Sass",
      "Concevez votre site web avec PHP et MySQL",
      "Adoptez une architecture MVC en PHP",
      "Déboguez l’interface de votre site internet",
      "Débutez avec React",
      "Créez une application React complète",
      "Gérez votre application React avec Redux et Redux Toolkit",
      "Apprenez à programmer en JavaScript",
      "Passez au Full Stack avec Node.js, Express et MongoDb",
      "Découvrez TypeScript",
    ],
    design: [
      "Appropriez-vous la démarche UX en pratique",
      "UX design : découvrez les fondamentaux",
      "Créez et appliquez une charte graphique",
    ],
    project: [
      "Initiez-vous à la gestion de projet agile",
      "Devenez développeur agile",
      "Adopter une culture agile",
    ],
    cyber: [
      "Découvrez les bases de la sécurité numérique",
      "Découvrez l'univers de la cybersécurité",
      "Sécurisez vos réseaux grâce aux VPN et FireWall",
    ],
    mark: ["Mettez en place un système de veille informationelle"],
    data: ["Utilisez ChatGPT pour améliorer votre productivité"],
    management: [
      "Révélez votre style de leadership",
      "Manager une équipe au quotidien",
    ],
    sysres: ["Découvrez le monde des systèmes d'information"],
  },
};

export { mooc };
